<template>
    <BasicModal @close="$emit('close')">
        <div class="container">
            <h2>{{ title }}</h2>
            <CheckSvg v-if="isSuccess" class="icon" />
            <CrossSvg v-else class="icon cross-icon" />
            <p v-if="description">
                {{ description }}
            </p>
            <slot />
        </div>
    </BasicModal>
</template>

<script>
    import CheckSvg from '@/assets/icons/circles/circle-check.svg?inline';
    import CrossSvg from '@/assets/icons/buttons/cross-black.svg?inline';
    import BasicModal from '@/components/Modals/BasicModal.vue';

    export default {
        name: 'SuccessModal',
        components: { BasicModal, CheckSvg, CrossSvg },
        props: {
            title: {
                type: String,
                default: '',
            },
            description: {
                type: String,
                default: '',
            },
            isSuccess: {
                type: Boolean,
                default: true,
            },
        },
        emits: ['close'],
    };
</script>

<style lang="scss" scoped>
    .container {
        @include column-align-center-justify-center;

        width: 100%;

        padding: 56px 8px;

        h2,
        p {
            text-align: center;
        }

        .icon {
            margin: 25px 0;
        }

        .cross-icon {
            width: 50px;
            height: 50px;

            & :deep() {
                path {
                    fill: $danger;
                }
            }
        }

        p {
            text-align: center;
            font-size: $font-14;
        }
    }

    @include media($lg) {
        .container {
            padding: 60px;

            h2 {
                font-size: $font-32;
            }

            p {
                font-size: $font-16;
            }
        }
    }
</style>
